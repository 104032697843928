import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const description = data.site.siteMetadata.description

  return (
    <Layout location={location} title={siteTitle} description={description}>
      <Seo title="404: Not Found" />
      <h1>Hello World.</h1>
      <p>I'm Marcus.</p>
      <p>Talking about Fitness & Health</p>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
